import React from 'react';
import './CalculationResults.css';

function CalculationResults({ results }) {
  if (!results || !results.wallResults) return null;

  return (
    <div className="calculation-results">
      <h2>Calculated Results</h2>
      
      <div className="results-grid">
        {results.wallResults.map((result, index) => (
          <div key={index} className="wall-result">
            <h3>Wall {index + 1}</h3>
            <ul>
              <li>Number of Panels: {result.numberOfPanels}</li>
              <li>Gap Width: {result.gapWidth.toFixed(2)} cm</li>
              {Math.abs(result.overhang) > 0.1 && (
                <li className={result.overhang > 0 ? 'overhang' : 'underhang'}>
                  {result.overhang > 0 ? 'Overhang' : 'Underhang'}: {Math.abs(result.overhang).toFixed(2)} cm
                </li>
              )}
            </ul>
          </div>
        ))}
      </div>

      <div className="alignment-info">
        {results.alignment.isAligned ? (
          <p className="aligned">✓ Gaps are consistent across walls</p>
        ) : (
          <p className="not-aligned">
            ⚠️ Gap width varies by {results.alignment.difference.toFixed(2)} cm
          </p>
        )}
      </div>
    </div>
  );
}

export default CalculationResults; 