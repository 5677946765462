import React, { useState } from 'react';
import WallManager from './components/WallManager';
import PanelVisualizer from './components/PanelVisualizer';
import CalculationResults from './components/CalculationResults';
import './App.css';
import { calculatePanelsForWall, checkAlignment } from './utils/calculations';

function App() {
  const [walls, setWalls] = useState([
    { id: 1, name: 'Wall 1', width: 287.0 },
    { id: 2, name: 'Wall 2', width: 315.0 },
    { id: 3, name: 'Wall 3', width: 328.0 }
  ]);
  
  const [settings, setSettings] = useState({
    panelWidth: 6.3,
    consistentGap: false,
    desiredGapWidth: 58.0
  });

  const [results, setResults] = useState(null);

  const calculatePanels = () => {
    try {
      const calculatedResults = walls.map(wall => {
        return calculatePanelsForWall(
          wall.width,
          settings.panelWidth,
          settings.consistentGap,
          settings.consistentGap ? settings.desiredGapWidth : null
        );
      });

      const alignment = checkAlignment(calculatedResults);
      
      setResults({
        wallResults: calculatedResults,
        alignment: alignment
      });
    } catch (error) {
      console.error('Calculation error:', error);
      // You might want to add proper error handling here
    }
  };

  return (
    <div className="app">
      <header>
        <h1>Panel Spacing Calculator</h1>
        <p>Calculate optimal panel spacing and alignment for your walls</p>
      </header>
      
      <main>
        <div className="calculator-container">
          <WallManager 
            walls={walls} 
            setWalls={setWalls}
            settings={settings}
            setSettings={setSettings}
          />
          
          <button 
            className="calculate-button"
            onClick={calculatePanels}
          >
            Calculate & Visualize
          </button>

          {results && (
            <>
              <CalculationResults results={results} />
              <PanelVisualizer 
                walls={walls}
                results={results}
                settings={settings}
              />
            </>
          )}
        </div>
      </main>
    </div>
  );
}

export default App; 