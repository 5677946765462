import React from 'react';
import './WallManager.css';

function WallManager({ walls, setWalls, settings, setSettings }) {
  const addWall = () => {
    const newId = Math.max(...walls.map(w => w.id)) + 1;
    setWalls([...walls, { 
      id: newId, 
      name: `Wall ${newId}`, 
      width: 300.0 
    }]);
  };

  const removeWall = (id) => {
    setWalls(walls.filter(wall => wall.id !== id));
  };

  const updateWall = (id, value) => {
    setWalls(walls.map(wall => 
      wall.id === id ? { ...wall, width: parseFloat(value) } : wall
    ));
  };

  return (
    <div className="wall-manager">
      <div className="walls-container">
        {walls.map(wall => (
          <div key={wall.id} className="wall-input">
            <label>{wall.name}</label>
            <input
              type="number"
              value={wall.width}
              onChange={(e) => updateWall(wall.id, e.target.value)}
              step="0.1"
            />
            <button 
              className="remove-wall"
              onClick={() => removeWall(wall.id)}
              aria-label="Remove wall"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="20" height="20">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        ))}
      </div>

      <button className="add-wall" onClick={addWall}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="20" height="20">
          <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
        </svg>
        Add Wall
      </button>

      <div className="settings">
        <div className="setting-input">
          <label>Panel Width (cm)</label>
          <input
            type="number"
            value={settings.panelWidth}
            onChange={(e) => setSettings({
              ...settings,
              panelWidth: parseFloat(e.target.value)
            })}
            step="0.1"
          />
        </div>

        <div className="setting-checkbox">
          <input
            type="checkbox"
            checked={settings.consistentGap}
            onChange={(e) => setSettings({
              ...settings,
              consistentGap: e.target.checked
            })}
          />
          <label>Consistent Gap Across Walls</label>
        </div>

        {settings.consistentGap && (
          <div className="setting-input">
            <label>Desired Gap Width (cm)</label>
            <input
              type="number"
              value={settings.desiredGapWidth}
              onChange={(e) => setSettings({
                ...settings,
                desiredGapWidth: parseFloat(e.target.value)
              })}
              step="0.1"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default WallManager; 