export function calculatePanelsForWall(wallWidth, panelWidth, consistentGap = false, desiredGapWidth = null) {
  if (consistentGap) {
    return calculateWithConsistentGap(wallWidth, panelWidth, desiredGapWidth);
  }
  return calculateOptimalPanels(wallWidth, panelWidth);
}

function calculateWithConsistentGap(wallWidth, panelWidth, gapWidth) {
  // Calculate number of panels with fixed gap width
  const nPanels = Math.floor((wallWidth + gapWidth) / (panelWidth + gapWidth));
  const totalLength = nPanels * panelWidth + (nPanels - 1) * gapWidth;
  const overhang = totalLength - wallWidth;

  return {
    numberOfPanels: nPanels,
    gapWidth: gapWidth,
    overhang: overhang
  };
}

function calculateOptimalPanels(wallWidth, panelWidth) {
  let optimalPanels = 1;
  let optimalGapWidth = 0;
  let minOverhang = Infinity;

  const maxPanels = Math.floor(wallWidth / panelWidth) + 2;

  for (let nPanels = 1; nPanels < maxPanels; nPanels++) {
    const numGaps = nPanels - 1;
    if (numGaps === 0) {
      const overhang = Math.abs(panelWidth - wallWidth);
      if (overhang < minOverhang) {
        minOverhang = overhang;
        optimalPanels = 1;
        optimalGapWidth = 0;
      }
      continue;
    }

    const gapWidth = (wallWidth - nPanels * panelWidth) / numGaps;
    if (gapWidth < 0) break;

    const totalLength = nPanels * panelWidth + numGaps * gapWidth;
    const overhang = Math.abs(totalLength - wallWidth);

    if (overhang < minOverhang) {
      minOverhang = overhang;
      optimalPanels = nPanels;
      optimalGapWidth = gapWidth;
    }
  }

  return {
    numberOfPanels: optimalPanels,
    gapWidth: optimalGapWidth,
    overhang: minOverhang
  };
}

export function checkAlignment(results) {
  const gaps = results.map(r => r.gapWidth);
  const minGap = Math.min(...gaps);
  const maxGap = Math.max(...gaps);
  const gapDiff = maxGap - minGap;

  return {
    isAligned: gapDiff < 2.0,
    difference: gapDiff
  };
} 