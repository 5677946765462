import React, { useEffect, useRef } from 'react';
import './PanelVisualizer.css';

function PanelVisualizer({ walls, results, settings }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!canvasRef.current || !results || !results.wallResults) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const maxWallWidth = Math.max(...walls.map(w => w.width));
    
    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Calculate scale factor
    const canvasUsableWidth = canvas.width - 40; // Leave margin
    const scaleFactor = canvasUsableWidth / maxWallWidth;

    // Draw walls
    walls.forEach((wall, index) => {
      const wallResult = results.wallResults[index];
      drawWall(ctx, wall, wallResult, settings.panelWidth, scaleFactor, index);
    });
  }, [walls, results, settings]);

  const drawWall = (ctx, wall, result, panelWidth, scaleFactor, index) => {
    const startX = 20;
    const startY = 20 + (index * 120);
    const wallHeight = 80;

    // Draw wall background
    ctx.fillStyle = '#f0f0f0';
    ctx.fillRect(startX, startY, wall.width * scaleFactor, wallHeight);
    ctx.strokeRect(startX, startY, wall.width * scaleFactor, wallHeight);

    // Draw wall label
    ctx.fillStyle = '#000';
    ctx.font = '14px Arial';
    ctx.fillText(wall.name, startX, startY - 5);

    let currentX = startX;
    
    // Draw panels and gaps
    for (let i = 0; i < result.numberOfPanels; i++) {
      // Draw panel
      ctx.fillStyle = '#8B4513';
      const panelWidthPx = panelWidth * scaleFactor;
      ctx.fillRect(currentX, startY, panelWidthPx, wallHeight);
      ctx.strokeRect(currentX, startY, panelWidthPx, wallHeight);
      currentX += panelWidthPx;

      // Draw gap
      if (i < result.numberOfPanels - 1) {
        const gapWidthPx = result.gapWidth * scaleFactor;
        ctx.setLineDash([4, 2]);
        ctx.beginPath();
        ctx.moveTo(currentX, startY);
        ctx.lineTo(currentX, startY + wallHeight);
        ctx.strokeStyle = 'blue';
        ctx.stroke();
        ctx.setLineDash([]);
        currentX += gapWidthPx;
      }
    }

    // Draw overhang if exists
    if (Math.abs(result.overhang) > 0.1) {
      const overhangPx = result.overhang * scaleFactor;
      ctx.fillStyle = result.overhang > 0 ? 'rgba(255,0,0,0.3)' : 'rgba(0,255,0,0.3)';
      if (result.overhang > 0) {
        ctx.fillRect(
          startX + wall.width * scaleFactor,
          startY,
          overhangPx,
          wallHeight
        );
      } else {
        ctx.fillRect(
          currentX,
          startY,
          Math.abs(overhangPx),
          wallHeight
        );
      }
    }
  };

  return (
    <div className="panel-visualizer">
      <canvas
        ref={canvasRef}
        width={600}
        height={400}
        className="visualization-canvas"
      />
    </div>
  );
}

export default PanelVisualizer; 